
import { Component, Prop, Watch } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'
import metrics from '@/modules/automation/data/metrics'

@Component
export default class MetricSelect extends VueStrong {
  @Prop({ type: String, required: true }) value!: FB.TriggerField
  @Prop({ type: String, required: true }) entityType!: FB.EntityTypes

  @Watch('value')
  onValueChange(newValue: FB.TriggerField) {
    this.$emit('input', newValue)
  }

  selectedEntity: FB.EntityTypes = 'campaign' as FB.EntityTypes
  selectedActionCategory = ''
  open = false

  created() {
    this.selectedEntity = this.entityType
  }

  get allowedEntities() {
    if (this.entityType === 'campaign') return ['ad', 'adset', 'campaign']
    if (this.entityType === 'adset') return ['ad', 'adset']
    return ['ad']
  }

  get metrics() {
    return metrics
      .map(metric => ({
        ...metric,
        items: metric.items.reduce((acc, { name, id }) => {
          if (this.selectedActionCategory) {
            const prefix = 'action_' + this.selectedActionCategory.replace(' ', '_')
            if (!id.startsWith(prefix)) return acc
          }
          acc.push({ id: this.selectedEntity + '$' + id, name })
          return acc
        }, [])
      }))
      .filter(metric => metric.items.length)
  }

  get metricsFlat() {
    return this.metrics.reduce((acc, metric) => {
      for (const subMetric of metric.items) {
        acc[subMetric.id] = subMetric.name
      }
      return acc
    }, {})
  }

  get displayValue() {
    if (!this.metricsFlat[this.value] || !this.selectedEntity) return ''
    return this.selectedEntity + ' • ' + this.metricsFlat[this.value]
  }

  onMetricChange(newValue: string) {
    this.$emit('input', newValue)
  }

  onMetricsClick(newValue: FB.TriggerField) {
    this.onMetricChange(newValue)
    this.open = false
  }

  onChangeSelectedEntity(entity: FB.EntityTypes) {
    this.selectedEntity = entity
    if (this.value?.includes('$')) {
      const newValue = this.value.split('.')[1]
      this.onMetricChange(this.selectedEntity + '$' + newValue)
    }
  }
}
