
import { Component, Prop } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'
import AutomationRule from '@/modules/automation/components/Rule.vue'
import defaultRule from '@/modules/automation/data/defaultRule'

@Component({
  name: 'AutomationRuleGroup',
  components: {
    AutomationRule
  }
})
export default class AutomationRuleGroup extends VueStrong {
  @Prop({ type: Object, required: true }) group!: yakkyo.IFacebookAutomationRuleGroup
  @Prop({ type: String, required: true }) entityType!: FB.EntityTypes

  toggleLogic() {
    this.group.logic = this.group.logic === 'AND' ? 'OR' : 'AND'
  }

  addButtons = [{ text: 'Add Rule' }, { text: 'Add Rule Group' }]

  addRule() {
    this.group.rules.push({ ...defaultRule })
  }

  addRuleGroup() {
    this.group.rulesGroups.push({
      logic: 'AND',
      rules: [{ ...defaultRule }],
      rulesGroups: []
    })
  }

  removeRule(index) {
    this.group.rules = this.group.rules.filter((_r, i) => i !== index)
    if (this.rulesCount === 0) this.$emit('remove')
  }

  removeRuleGroup(index) {
    this.group.rulesGroups = this.group.rulesGroups.filter((_r, i) => i !== index)
    if (this.rulesCount === 0) this.$emit('remove')
  }

  get rulesCount() {
    return this.group.rules.length + this.group.rulesGroups.length
  }

  onDrop(evt: DragEventInit) {
    // evt.dataTransfer.setData('dropped', 'true')
    localStorage.setItem('dropped', 'true')
    const data = JSON.parse(evt.dataTransfer.getData('rule'))
    this.group.rules.push(data)
  }
}
