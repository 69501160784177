
import { Component, Prop } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'
import MetricSelect from './MetricSelect.vue'

@Component({
  name: 'AutomationRule',
  components: {
    MetricSelect
  }
})
export default class AutomationRule extends VueStrong {
  @Prop({ type: Object, required: true }) rule!: yakkyo.IFacebookAutomationRule
  @Prop({ type: String, required: true }) entityType!: FB.EntityTypes

  timeIntervals = [
    { text: 'Today', value: 'today' },
    { text: 'Yesterday', value: 'yesterday' },
    { text: 'Today and Yesterday', value: 'today and yesterday' },
    { text: 'Last 2 days', value: 'last 2 days' },
    { text: 'Last 3 days', value: 'last 3 days' },
    { text: 'Last 7 days', value: 'last 7 days' },
    { text: 'Last 14 days', value: 'last 14 days' },
    { text: 'Last 30 days', value: 'last 30 days' },
    { text: 'Last 2 days (including today)', value: 'last 2 days (including today)' },
    { text: 'Last 3 days (including today)', value: 'last 3 days (including today)' },
    { text: 'Last 7 days (including today)', value: 'last 7 days (including today)' },
    { text: 'Last 14 days (including today)', value: 'last 14 days (including today)' },
    { text: 'Last 30 days (including today)', value: 'last 30 days (including today)' },
    { text: 'Lifetime', value: 'lifetime' },
    { text: 'Custom', value: 'custom' }
  ]
  operators = [
    { text: 'Is equal', value: 'EQUAL' },
    { text: 'Is not equal', value: 'NOT_EQUAL' },
    { text: 'Is greather', value: 'GREATER_THAN' },
    // { text: 'Is greather or equal', value: 'gte' },
    { text: 'Is lower', value: 'LESS_THAN' },
    // { text: 'Is lower or equal', value: 'lte' },
    { text: 'Contains', value: 'CONTAIN' },
    { text: 'Does not contain', value: 'NOT_CONTAIN' }
  ]

  startDrag(evt: DragEventInit) {
    evt.dataTransfer.dropEffect = 'move'
    evt.dataTransfer.effectAllowed = 'move'
    evt.dataTransfer.setData('rule', JSON.stringify(this.rule))

    localStorage.removeItem('dropped')
  }

  dragEnd(evt: DragEventInit) {
    // const hasBeenDropped = evt.dataTransfer.getData('dropped')
    const hasBeenDropped = localStorage.getItem('dropped')
    if (hasBeenDropped) this.$emit('remove')

    localStorage.removeItem('dropped')
    // evt.dataTransfer.clearData()
  }
}
