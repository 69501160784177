type EntityField =
  | 'daily_budget'
  | 'hours_since_creation'
  | 'daily_spend_cap'
  | 'lifetime_spend_cap'
  | 'lifetime_budget'
  | 'bid_amount'
  | 'lifetime_min_spend_target'
  | 'daily_min_spend_target'
  | 'start_time'
  | 'end_time'
  | 'budget_remaining'

type TriggerAction =
  | FB.Concat<'action_count', FB.IAdInsightsActionEnum, '#'>
  | FB.Concat<'action_value', FB.IAdInsightsActionEnum, '#'>
  | FB.Concat<'action_cost', FB.IAdInsightsActionEnum, '#'>
  | FB.Concat<'action_cost_unique', FB.IAdInsightsActionEnum, '#'>
  | FB.Concat<'action_count_unique', FB.IAdInsightsActionEnum, '#'> // not documented but it works

type TriggerField =
  | keyof FB.IAdInsights
  | FB.Concat<FB.EntityTypes, keyof FB.IAdInsights>
  | TriggerAction
  | FB.Concat<FB.EntityTypes, TriggerAction>
  | EntityField
  | FB.Concat<'custom_count', FB.EntityTypes, '#'>
  | FB.Concat<'custom_count', FB.Concat<FB.EntityTypes, 'active', ':'>, '#'>
  | 'custom.random_number'

type Metric = {
  id: TriggerField
  // id: FB.TriggerField
  name: string
}

type MetricGroup = {
  id: string
  name: string
  icon: string
  items: Metric[]
}
const metrics: MetricGroup[] = [
  {
    id: 'most-common',
    name: 'Most common',
    icon: 'mdi-trending-up',
    items: [
      { id: 'action_count#offsite_conversion.fb_pixel_add_to_cart', name: 'Website adds to cart' },
      { id: 'action_count#offsite_conversion.fb_pixel_lead', name: 'Website leads' },
      { id: 'action_count#offsite_conversion.fb_pixel_purchase', name: 'Website purchases' },
      { id: 'custom_count#ad:active', name: 'Active ads in ad set' },
      { id: 'custom_count#adset:active', name: 'Active ad sets in campaign' },
      { id: 'custom_count#campaign:active', name: 'Active campaigns in account' },
      { id: 'custom_count#ad', name: 'Ads in ad set' },
      { id: 'custom_count#adset', name: 'Ad sets in campaign' },
      { id: 'custom_count#campaign', name: 'Campaigns in account' },
      { id: 'daily_budget', name: 'Daily budget' },
      { id: 'hours_since_creation', name: 'Hours since creation' },
      { id: 'daily_spend_cap', name: 'Maximum daily spend limit' },
      { id: 'lifetime_spend_cap', name: 'Maximum lifetime spend limit' },
      { id: 'daily_min_spend_target', name: 'Minimum daily spend target' },
      { id: 'lifetime_min_spend_target', name: 'Minimum lifetime spend target' },
      { id: 'action_cost#offsite_conversion.fb_pixel_purchase', name: 'Cost per website purchase' },
      { id: 'purchase_roas', name: 'Purchase ROAS' },
      { id: 'website_purchase_roas', name: 'Website purchase ROAS' },
      { id: 'spend', name: 'Spend' },
      { id: 'end_time', name: 'Time less than' },
      { id: 'start_time', name: 'Time greater than' }
    ]
  },
  {
    id: 'general',
    name: 'General',
    icon: 'mdi-advertisements',
    items: [
      { id: 'cpm', name: 'CPM' },
      { id: 'cpp', name: 'Cost per 1000 people reached' },
      { id: 'bid_amount', name: 'Bid amount' },
      { id: 'budget_remaining', name: 'Remaining budget' },
      { id: 'lifetime_budget', name: 'Lifetime budget' },
      { id: 'custom.random_number', name: 'Random number from 0 to 100' },
      { id: 'frequency', name: 'Frequency' },
      { id: 'impressions', name: 'Impressions' },
      { id: 'reach', name: 'Reach' }
    ]
  },
  {
    id: 'website-standard',
    name: 'Website standard events',
    icon: 'mdi-web',
    items: [
      { id: 'action_value#offsite_conversion.fb_pixel_lead', name: 'Website leads value' },
      { id: 'action_value#offsite_conversion.fb_pixel_purchase', name: 'Website purchase value' },
      { id: 'action_count#landing_page_view', name: 'Landing page views' },
      { id: 'action_count#offsite_conversion.fb_pixel_add_payment_info', name: 'Website adds of payment info' }, // prettier-ignore
      { id: 'action_count#offsite_conversion.fb_pixel_add_to_wishlist', name: 'Website adds to wishlist' },
      { id: 'action_count#offsite_conversion.fb_pixel_complete_registration', name: 'Website registrations completed' }, // prettier-ignore
      { id: 'action_count#offsite_conversion.fb_pixel_initiate_checkout', name: 'Website checkouts initiated' }, // prettier-ignore
      { id: 'action_count#offsite_conversion.fb_pixel_view_content', name: 'Website content views' },
      // { id: '', name: 'Average website purchase value' },
      { id: 'action_count#start_trial_website', name: 'Website trials started' },
      { id: 'action_cost#start_trial_website', name: 'Cost per website trial started' },
      { id: 'action_count#subscribe_website', name: 'Website subscriptions' },
      { id: 'action_cost#subscribe_website', name: 'Cost per website subscription' },
      { id: 'action_cost#landing_page_view', name: 'Cost per landing page view' },
      { id: 'action_cost_unique#landing_page_view', name: 'Cost per unique landing page view' },
      { id: 'action_cost_unique#offsite_conversion.fb_pixel_add_to_cart', name: 'Cost per unique website add to cart' }, // prettier-ignore
      { id: 'action_cost_unique#offsite_conversion.fb_pixel_purchase', name: 'Cost per unique website purchase' },
      { id: 'action_cost#offsite_conversion.fb_pixel_add_payment_info', name: 'Cost per website add of payment info' }, // prettier-ignore
      { id: 'action_cost#offsite_conversion.fb_pixel_add_to_cart', name: 'Cost per website add to cart' },
      { id: 'action_cost#offsite_conversion.fb_pixel_add_to_wishlist', name: 'Cost per website add to wishlist' }, // prettier-ignore
      { id: 'action_cost#offsite_conversion.fb_pixel_complete_registration', name: 'Cost per website registration completed' }, // prettier-ignore
      { id: 'action_cost#offsite_conversion.fb_pixel_initiate_checkout', name: 'Cost per website checkout initiated' }, // prettier-ignore
      { id: 'action_cost#offsite_conversion.fb_pixel_lead', name: 'Cost per website lead' },
      { id: 'action_cost#offsite_conversion.fb_pixel_view_content', name: 'Cost per website content view' },
      { id: 'action_count_unique#landing_page_view', name: 'Unique landing page views' },
      { id: 'action_count_unique#offsite_conversion.fb_pixel_add_to_cart', name: 'Unique website adds to cart' }, // prettier-ignore
      { id: 'action_count_unique#offsite_conversion.fb_pixel_purchase', name: 'Unique website purchases' },
      { id: 'action_count_unique#offsite_conversion.fb_pixel_view_content', name: 'Unique website content views' }, // prettier-ignore
      { id: 'action_cost_unique#offsite_conversion.fb_pixel_view_content', name: 'Cost per unique website content view' }, // prettier-ignore
      { id: 'action_count_unique#offsite_conversion.fb_pixel_add_payment_info', name: 'Unique website adds of payment info' }, // prettier-ignore
      { id: 'action_cost_unique#offsite_conversion.fb_pixel_add_payment_info', name: 'Cost per unique website add of payment info' }, // prettier-ignore
      { id: 'action_count_unique#offsite_conversion.fb_pixel_complete_registration', name: 'Unique website registrations completed' }, // prettier-ignore
      { id: 'action_cost_unique#offsite_conversion.fb_pixel_complete_registration', name: 'Cost per unique website registration completed' } // prettier-ignore
    ]
  },
  {
    id: 'mobile-standard',
    name: 'Mobile app standard events',
    icon: 'mdi-cellphone',
    items: [
      { id: 'action_value#app_custom_event.fb_mobile_achievement_unlocked', name: 'Achievements unlocked value' }, // prettier-ignore
      { id: 'action_value#app_use', name: 'Mobile app sessions value' }, // TODO: check if this is correct
      { id: 'action_count#app_custom_event.fb_mobile_level_achieved', name: 'Levels completed value' },
      { id: 'action_value#app_custom_event.fb_mobile_purchase', name: 'Mobile app purchase value' },
      { id: 'action_value#app_custom_event.fb_mobile_rate', name: 'Ratings submitted value' },
      { id: 'action_value#app_custom_event.fb_mobile_tutorial_completion', name: 'Tutorials completed value' }, // prettier-ignore
      { id: 'action_count#app_custom_event.fb_mobile_achievement_unlocked', name: 'Achievements unlocked' },
      { id: 'action_count#app_use', name: 'Mobile app sessions' }, // TODO: check if this is correct. Maybe fb_mobile_activate_app?
      { id: 'action_count#app_custom_event.fb_mobile_add_to_cart', name: 'Mobile app adds to cart' },
      { id: 'action_count#app_custom_event.fb_mobile_complete_registration', name: 'Mobile app registrations completed' }, // prettier-ignore
      // { id: '', name: 'Mobile app D2 retention' },
      // { id: '', name: 'Mobile app D7 retention' },
      { id: 'action_count#app_custom_event.fb_mobile_initiated_checkout', name: 'Mobile app checkouts initiated' }, // prettier-ignore
      { id: 'action_count#app_custom_event.fb_mobile_level_achieved', name: 'Levels completed' },
      { id: 'action_count#app_custom_event.fb_mobile_purchase', name: 'Mobile app purchases' },
      { id: 'action_count#app_custom_event.fb_mobile_rate', name: 'Ratings submitted' },
      { id: 'action_count#app_custom_event.fb_mobile_tutorial_completion', name: 'Tutorials completed' },
      { id: 'action_count#games.plays', name: 'Game plays' },
      { id: 'action_count#mobile_app_install', name: 'Mobile app installs' },
      { id: 'action_cost#app_custom_event.fb_mobile_achievement_unlocked', name: 'Cost per achievement unlocked' }, // prettier-ignore
      { id: 'action_cost#app_custom_event.fb_mobile_add_to_cart', name: 'Cost per mobile app add to cart' },
      { id: 'action_cost#app_custom_event.fb_mobile_complete_registration', name: 'Cost per mobile app registration completed' }, // prettier-ignore
      { id: 'action_cost#app_custom_event.fb_mobile_initiated_checkout', name: 'Cost per mobile app checkout initiated' }, // prettier-ignore
      { id: 'action_cost#app_custom_event.fb_mobile_level_achieved', name: 'Cost per level completed' },
      { id: 'action_cost#app_custom_event.fb_mobile_purchase', name: 'Cost per mobile app purchase' },
      { id: 'action_count#start_trial_mobile_app', name: 'Mobile app trials started' },
      { id: 'action_cost#app_custom_event.fb_mobile_achievement_unlocked', name: 'Cost per mobile app achievement unlocked' }, // prettier-ignore
      { id: 'action_cost#app_use', name: 'Cost per session' }, // TODO: check if this is correct
      // { id: '', name: 'Cost per mobile app D2 retention' },
      // { id: '', name: 'Cost per mobile app D7 retention' },
      { id: 'action_cost#app_custom_event.fb_mobile_rate', name: 'Cost per rating submitted' },
      { id: 'action_cost#start_trial_mobile_app', name: 'Cost per mobile app trial started' },
      { id: 'action_cost#games.plays', name: 'Cost per game play' },
      { id: 'action_cost_unique#app_custom_event.fb_mobile_achievement_unlocked', name: 'Cost per unique achievement unlocked' }, // prettier-ignore
      { id: 'action_cost_unique#app_use', name: 'Cost per unique session' }, // TODO: check if this is correct
      { id: 'action_cost_unique#app_custom_event.fb_mobile_level_achieved', name: 'Cost per unique level completed' }, // prettier-ignore
      { id: 'action_cost_unique#app_custom_event.fb_mobile_rate', name: 'Cost per unique rating submitted' },
      { id: 'action_cost_unique#app_custom_event.fb_mobile_tutorial_completion', name: 'Cost per unique tutorial completed' }, // prettier-ignore
      { id: 'action_cost_unique#app_custom_event.fb_mobile_add_payment_info', name: 'Cost per unique mobile app add of payment info' }, // prettier-ignore
      { id: 'action_cost#mobile_app_install', name: 'Cost per mobile app install' },
      { id: 'mobile_app_purchase_roas', name: 'Mobile app purchase ROAS' },
      { id: 'action_count_unique#app_custom_event.fb_mobile_achievement_unlocked', name: 'Unique achievements unlocked' }, // prettier-ignore
      { id: 'action_count_unique#app_use', name: 'Unique sessions' }, // TODO: check if this is correct. Maybe fb_mobile_activate_app?
      { id: 'action_count_unique#app_custom_event.fb_mobile_level_achieved', name: 'Unique levels completed' }, // prettier-ignore
      { id: 'action_count_unique#app_custom_event.fb_mobile_rate', name: 'Unique ratings submitted' },
      { id: 'action_count_unique#app_custom_event.fb_mobile_tutorial_completion', name: 'Unique tutorials completed' }, // prettier-ignore
      { id: 'action_count_unique#app_custom_event.fb_mobile_add_payment_info', name: 'Unique mobile app adds of payment info' } // prettier-ignore
    ]
  },
  // TODO: shall we alwayse use omni in place of offsite_conversion here? If so, how to handle stuff such as add_payment_info?
  {
    id: 'total-standard',
    name: 'Standard events totals',
    icon: 'mdi-standard-definition',
    items: [
      { id: 'action_value#offsite_conversion.fb_pixel_add_payment_info', name: 'Adds of payment info value' }, // prettier-ignore
      { id: 'action_value#offsite_conversion.fb_pixel_add_to_wishlist', name: 'Adds to wishlist value' },
      { id: 'action_value#offsite_conversion.fb_pixel_lead', name: 'Leads value' },
      { id: 'action_value#offsite_conversion.fb_pixel_add_to_cart', name: 'Adds to cart value' },
      { id: 'action_value#offsite_conversion.fb_pixel_complete_registration', name: 'Registrations completed value' }, // prettier-ignore
      { id: 'action_value#offsite_conversion.fb_pixel_initiate_checkout', name: 'Checkouts initiated value' }, // prettier-ignore
      { id: 'action_value#offsite_conversion.fb_pixel_purchase', name: 'Purchases value' },
      { id: 'action_value#offsite_conversion.fb_pixel_search', name: 'Searches value' },
      { id: 'action_value#credit_spent', name: 'Credit spends value' },
      { id: 'action_count#offsite_conversion.fb_pixel_view_content', name: 'Content views value' },
      { id: 'action_count#offsite_conversion.fb_pixel_add_payment_info', name: 'Adds of payment info' },
      { id: 'action_count#offsite_conversion.fb_pixel_add_to_wishlist', name: 'Adds to wishlist' },
      { id: 'action_count#offsite_conversion.fb_pixel_lead', name: 'Leads' },
      { id: 'action_count#offsite_conversion.fb_pixel_add_to_cart', name: 'Adds to cart' },
      { id: 'action_count#offsite_conversion.fb_pixel_complete_registration', name: 'Registrations completed' }, // prettier-ignore
      { id: 'action_count#offsite_conversion.fb_pixel_initiate_checkout', name: 'Checkouts initiated' },
      { id: 'action_count#offsite_conversion.fb_pixel_purchase', name: 'Purchases' },
      { id: 'action_count#offsite_conversion.fb_pixel_search', name: 'Searches' },
      { id: 'action_count#credit_spent', name: 'Credit spends' },
      { id: 'action_count#offsite_conversion.fb_pixel_view_content', name: 'Content views' },
      { id: 'action_count#onsite_conversion.post_save', name: 'Post saves' },
      { id: 'action_value#contact_total', name: 'Contacts value' },
      { id: 'action_value#customize_product_total', name: 'Customize product conversion value' },
      { id: 'action_value#donate_total', name: 'Donations value' },
      { id: 'action_value#find_location_total', name: 'Location searches value' },
      { id: 'action_value#schedule_total', name: 'Schedule value' },
      { id: 'action_value#start_trial_total', name: 'Trials started value' },
      { id: 'action_value#submit_application_total', name: 'Applications submitted value' },
      { id: 'action_value#subscribe_total', name: 'Subscriptions value' },
      { id: 'action_count#contact_total', name: 'Contacts' },
      { id: 'action_count#customize_product_total', name: 'Products customized' },
      { id: 'action_count#donate_total', name: 'Donations' },
      { id: 'action_count#find_location_total', name: 'Location searches' },
      { id: 'action_count#schedule_total', name: 'Appointments scheduled' },
      { id: 'action_count#start_trial_total', name: 'Trials started' },
      { id: 'action_count#submit_application_total', name: 'Applications submitted' },
      { id: 'action_count#subscribe_total', name: 'Subscriptions' },
      { id: 'action_cost#offsite_conversion.fb_pixel_add_payment_info', name: 'Cost per add of payment info' }, // prettier-ignore
      { id: 'action_cost#offsite_conversion.fb_pixel_add_to_wishlist', name: 'Cost per add to wishlist' },
      { id: 'action_cost#contact_total', name: 'Cost per contact' },
      { id: 'action_cost#customize_product_total', name: 'Cost per product customized' },
      { id: 'action_cost#donate_total', name: 'Cost per donation' },
      { id: 'action_cost#find_location_total', name: 'Cost per location find' },
      { id: 'action_cost#schedule_total', name: 'Cost per appointment scheduled' },
      { id: 'action_cost#start_trial_total', name: 'Cost per trial started' },
      { id: 'action_cost#submit_application_total', name: 'Cost per application submitted' },
      { id: 'action_cost#subscribe_total', name: 'Cost per subscription' },
      { id: 'action_cost#lead', name: 'Cost per lead' }, // TODO: shall we exlude on facebook?
      { id: 'action_cost#onsite_conversion.lead_grouped', name: 'Cost per on-Facebook lead' },
      { id: 'action_cost#offsite_conversion.fb_pixel_add_to_cart', name: 'Cost per add to cart' },
      { id: 'action_cost#offsite_conversion.fb_pixel_complete_registration', name: 'Cost per registration completed' }, // prettier-ignore
      { id: 'action_cost#offsite_conversion.fb_pixel_initiate_checkout', name: 'Cost per checkout initiated' }, // prettier-ignore
      { id: 'action_cost#offsite_conversion.fb_pixel_purchase', name: 'Cost per purchase' },
      { id: 'action_cost#find_location_total', name: 'Cost per search' },
      { id: 'action_cost#credit_spent', name: 'Cost per credit spend' },
      { id: 'action_count#offsite_conversion.fb_pixel_view_content', name: 'Cost per content view' },
      { id: 'action_cost#onsite_conversion.post_save', name: 'Cost per post save' },
      { id: 'action_cost_unique#offsite_conversion.fb_pixel_add_to_wishlist', name: 'Cost per unique add to wishlist' }, // prettier-ignore
      { id: 'action_cost_unique#offsite_conversion.fb_pixel_add_to_cart', name: 'Cost per unique add to cart' }, // prettier-ignore
      { id: 'action_cost_unique#offsite_conversion.fb_pixel_initiate_checkout', name: 'Cost per unique checkout initiated' }, // prettier-ignore
      { id: 'action_cost_unique#offsite_conversion.fb_pixel_purchase', name: 'Cost per unique purchase' },
      { id: 'action_count_unique#offsite_conversion.fb_pixel_add_to_wishlist', name: 'Unique adds to wishlist' }, // prettier-ignore
      { id: 'action_count_unique#offsite_conversion.fb_pixel_add_to_cart', name: 'Unique adds to cart' },
      { id: 'action_count_unique#offsite_conversion.fb_pixel_initiate_checkout', name: 'Unique checkouts initiated' }, // prettier-ignore
      { id: 'action_count_unique#offsite_conversion.fb_pixel_purchase', name: 'Unique purchases' }
    ]
  },
  {
    id: 'engagement',
    name: 'Engagement',
    icon: 'mdi-account-group',
    items: [
      { id: 'action_count#comment', name: 'Post comments' },
      { id: 'action_count#like', name: 'Page likes' },
      { id: 'action_count#post', name: 'Post shares' },
      { id: 'action_count#post_engagement', name: 'Post engagement' },
      { id: 'action_count#post_reaction', name: 'Post reactions' },
      { id: 'action_count#rsvp', name: 'Event responses' },
      { id: 'action_count#video_view', name: '3-second video views' },
      { id: 'clicks', name: 'Clicks' },
      { id: 'action_cost#comment', name: 'Cost per comment' },
      { id: 'action_cost#like', name: 'Cost per page like' },
      { id: 'action_cost#post', name: 'Cost per post share' },
      { id: 'action_cost#post_engagement', name: 'Cost per engagement' },
      { id: 'action_cost#post_reaction', name: 'Cost per reaction' },
      { id: 'action_cost#rsvp', name: 'Cost per event response' },
      { id: 'action_cost#video_view', name: 'Cost per video view' },
      { id: 'cost_per_estimated_ad_recallers', name: 'Cost per estimated ad recall lift (people)' },
      // { id: '', name: 'CPC (Link)' },
      { id: 'cost_per_inline_post_engagement', name: 'Cost per inline post engagement' },
      { id: 'action_cost#outbound_click', name: 'Cost per outbound click' },
      // { id: '', name: 'Cost per unique click' },
      { id: 'action_cost_unique#outbound_click', name: 'Cost per unique outbound click' },
      { id: 'action_cost_unique#link_click', name: 'Cost per unique click (Link)' },
      { id: 'cpc', name: 'CPC' },
      { id: 'ctr', name: 'CTR' },
      { id: 'estimated_ad_recallers', name: 'Estimated ad recall lift (people)' },
      // { id: '', name: 'CTR (Link clicks)' },
      { id: 'action_count#link_click', name: 'Link clicks' },
      { id: 'inline_post_engagement', name: 'Inline post engagement' },
      { id: 'outbound_clicks', name: 'Outbound clicks' },
      { id: 'outbound_clicks_ctr', name: 'CTR (Outbound clicks)' },
      { id: 'action_count_unique#outbound_click', name: 'Unique outbound clicks' },
      { id: 'quality_ranking', name: 'Quality Ranking' },
      { id: 'engagement_rate_ranking', name: 'Engagement Rate Ranking' },
      { id: 'conversion_rate_ranking', name: 'Conversion Rate Ranking' },
      // { id: '', name: 'Unique clicks' },
      // { id: '', name: 'Unique CTR' },
      // { id: '', name: 'Unique CTR (Link)' },
      { id: 'action_count_unique#link_click', name: 'Unique clicks (Link)' },
      { id: 'video_15_sec_watched_actions', name: '15-second video views' },
      { id: 'video_30_sec_watched_actions', name: '30-second video views' },
      { id: 'video_p100_watched_actions', name: 'Video watches at 100%' },
      { id: 'video_p25_watched_actions', name: 'Video watches at 25%' },
      { id: 'video_p50_watched_actions', name: 'Video watches at 50%' },
      { id: 'video_p75_watched_actions', name: 'Video watches at 75%' },
      { id: 'video_p95_watched_actions', name: 'Video watches at 95%' }
    ]
  },
  {
    id: 'messaging',
    name: 'Messaging',
    icon: 'mdi-message',
    items: [
      { id: 'action_count#onsite_conversion.messaging_block', name: 'New blocked messaging connections' },
      { id: 'action_count#onsite_conversion.messaging_conversation_started_7d', name: 'New messaging connections last 7 days' }, // prettier-ignore
      { id: 'action_count#onsite_conversion.messaging_first_reply', name: 'New messaging connection' },
      { id: 'action_cost#onsite_conversion.messaging_block', name: 'Cost per blocked messaging connection' },
      { id: 'action_cost#onsite_conversion.messaging_conversation_started_7d', name: 'Cost per new messaging connection last 7 days' }, // prettier-ignore
      {
        id: 'action_cost#onsite_conversion.messaging_first_reply',
        name: 'Cost per new messaging connection'
      }
    ]
  },
  // {
  //   id: 'Offline standard events',
  //   name: 'Offline standard events',
  //   items: [
  //     { id: 'action_value#contact_offline', name: 'Offline leads value' },
  //     { id: '', name: 'Offline purchase value' },
  //     { id: 'action_count#submit_application_offline', name: 'Offline registrations completed' }, // TODO: not really sure
  //     { id: 'action_count#contact_offline', name: 'Offline leads' },
  //     { id: '', name: 'Offline purchases' },
  //     { id: '', name: 'Cost per offline registration completed' },
  //     { id: '', name: 'Cost per offline purchase' },
  //     { id: '', name: 'Cost per offline unique checkouts initiated' },
  //     { id: 'action_cost#contact_offline', name: 'Cost per offline lead' },
  //     { id: '', name: 'Unique offline checkouts initiated' },
  //     { id: '', name: 'Unique offline registrations completed' },
  //     { id: '', name: 'Cost per offline unique registration completed' }
  //   ]
  // },
  {
    id: 'on-facebook-standard',
    name: 'On-Facebook standard events',
    icon: 'mdi-facebook',
    items: [
      { id: 'action_value#onsite_conversion.purchase', name: 'On-Facebook purchase value' },
      { id: 'action_value#onsite_conversion.messaging_first_reply', name: 'On-Facebook leads' }, // TODO: check if this is correct. We're assuming a new message means a new lead
      { id: 'action_count#onsite_conversion.flow_complete', name: 'On-Facebook workflow completions' },
      { id: 'action_count#onsite_conversion.purchase', name: 'On-Facebook purchases' },
      { id: 'action_cost#onsite_conversion.flow_complete', name: 'Cost per on-Facebook workflow completion' }, // prettier-ignore
      { id: 'action_cost#onsite_conversion.purchase', name: 'Cost per on-Facebook purchase' },
      { id: 'action_cost#onsite_conversion.messaging_first_reply', name: 'Cost per on-Facebook lead' } // TODO: check if this is correct. We're assuming a new message means a new lead
    ]
  }
  // https://developers.facebook.com/docs/marketing-api/collaborative-ads/
  // {
  //   id: 'Collaborative ads',
  //   name: 'Collaborative ads',
  //   items: [
  //     { id: '', name: 'Adds to Cart with Shared Items' },
  //     { id: '', name: 'Cost per Adds to Cart with Shared Items' },
  //     { id: '', name: 'Adds to Cart Conversion Value for Shared Items' },
  //     { id: '', name: 'Website Adds to Cart with Shared Items' },
  //     { id: '', name: 'Cost per Website Adds to Cart with Shared Items' },
  //     { id: '', name: 'Website Adds to Cart Conversion Value for Shared Items' },
  //     { id: '', name: 'Mobile App Adds to Cart with Shared Items' },
  //     { id: '', name: 'Cost per Mobile App Adds to Cart with Shared Items' },
  //     { id: '', name: 'Mobile App Adds to Cart Conversion Value for Shared Items' },
  //     { id: '', name: 'Purchases with Shared Items' },
  //     { id: '', name: 'Cost per Purchase with Shared Items' },
  //     { id: '', name: 'Purchases Conversion Value for Shared Items' },
  //     { id: '', name: 'Content Views with Shared Items' },
  //     { id: '', name: 'Cost per Content Views with Shared Items' },
  //     { id: '', name: 'Content Views Conversion Value for Shared Items' },
  //     { id: '', name: 'Website Content Views with Shared Items' },
  //     { id: '', name: 'Cost per Website Content Views with Shared Items' },
  //     { id: '', name: 'Website Content Views Conversion Value for Shared Items' },
  //     { id: '', name: 'Mobile App Content Views with Shared Items' },
  //     { id: '', name: 'Cost per Mobile App Content Views with Shared Items' },
  //     { id: '', name: 'Mobile App Content Views Conversion Value for Shared Items' },
  //     { id: '', name: 'Purchase ROAS for Shared Items' },
  //     { id: '', name: 'Website Purchase ROAS for Shared Items' },
  //     { id: '', name: 'Mobile App Purchase ROAS for Shared Items' }
  //   ]
  // }
]

// const metrics: (entityType: FB.EntityTypes) => MetricGroup[] = type => [
//   {
//     id: 'ad_performance',
//     name: 'Ad Performance',
//     items: [
//       { id: `${type}.reach`, name: 'Reach' },
//       { id: `${type}.impressions`, name: 'Impressions' },
//       { id: `${type}.daily_budget`, name: 'Daily Budget' },
//       { id: `${type}.spent`, name: 'Ad Spend' },
//       { id: `${type}.link_click`, name: 'Link Clicks' },
//       { id: `${type}.clicks`, name: 'Clicks' },
//       { id: `${type}.cpc`, name: 'CPC' },
//       { id: `${type}.ctr`, name: 'CTR' },

//       // { id: `${type}.cpc_click`, name: 'CPC (Link click)' },
//       // { id: `${type}.ctr_link`, name: 'CTR (Link)' },
//       // { id: `${type}.clicks (all)`, name: 'Clicks (All)' },
//       // { id: `${type}.cpc_all`, name: 'CPC (All)' },
//       // { id: `${type}.ctr_all`, name: 'CTR (All)' },
//       { id: `${type}.frequency`, name: 'Frequency' },
//       { id: `${type}.cpm`, name: 'CPM (Cost per 1000 Impressions)' },

//       // { id: `${type}.purchase_roas`, name: 'Purchase ROAS' }, TODO: find where is this metric, seemd to be only in the InsightsFiltersSchedule, not in the InsightsFiltersTrigger
//       { id: `${type}.cost_per_lead_fb`, name: 'Cost per Lead' },
//       { id: `${type}.leadgen`, name: 'Total Leads' } // TODO: not quite sure about this one

//       // TODO: find where is this metric
//       // { id: `${type}.leads_conversion_value`, name: 'Leads Conversion Value' },
//       // { id: `${type}.total_purchases`, name: 'Total Purchases' },
//       // { id: `${type}.cost_per_purchase`, name: 'Cost per Purchase' },
//       // { id: `${type}.purchase_value`, name: 'Purchase Value' }
//     ]
//   },
//   // TODO: find where are these metrics? they are not in the InsightsFiltersTrigger
//   {
//     id: 'pixel',
//     name: 'Pixel',
//     items: [
//       { id: `${type}.offsite_conversion.fb_pixel_view_content`, name: 'View Content' },
//       { id: `${type}.offsite_conversion.fb_pixel_add_to_cart`, name: 'Adds to Cart' },
//       { id: `${type}.offsite_conversion.fb_pixel_initiate_checkout`, name: 'Checkouts Initiated' },
//       { id: `${type}.offsite_conversion.fb_pixel_add_payment_info`, name: 'Added Payment Info' },
//       // { id: `${type}.cost_per_view_content`, name: 'Cost per View Content' },
//       // { id: `${type}.cost_per_adds_to_cart`, name: 'Cost per Adds to Cart' },
//       // { id: `${type}.cost_per_checkouts_initiated`, name: 'Cost per Checkouts Initiated' },
//       // { id: `${type}.cost_per_added_payment_info`, name: 'Cost per Added Payment Info' },
//       // { id: `${type}.contacts`, name: 'Contacts' },
//       // { id: `${type}.cost_per_contact`, name: 'Cost per Contact' },
//       { id: `${type}.offsite_conversion.fb_pixel_complete_registration`, name: 'Registrations Completed' },
//       // { id: `${type}.cost_per_registration`, name: 'Cost per Registration' },
//       // { id: `${type}.trials_started`, name: 'Trials Started' },
//       // { id: `${type}.cost_per_trial_started`, name: 'Cost per Trial started' },
//       { id: `${type}.offsite_conversion.fb_pixel_lead`, name: 'Total Leads' },
//       { id: `${type}.offsite_conversion.fb_pixel_search`, name: 'Searches' },
//       // { id: `${type}.cost_per_search`, name: 'Cost per Search' },
//       // { id: `${type}.subscriptions`, name: 'Subscriptions' },
//       // { id: `${type}.cost_per_subscription`, name: 'Cost per Subscription' },
//       { id: `${type}.app_custom_event.fb_mobile_achievement_unlocked`, name: 'Mobile App Feature Unlocks' },
//       { id: `${type}.app_custom_event.fb_mobile_activate_app`, name: 'Mobile App Starts' },
//       { id: `${type}.app_custom_event.fb_mobile_add_payment_info`, name: 'Mobile App Payment Details' },
//       { id: `${type}.app_custom_event.fb_mobile_add_to_cart`, name: 'Mobile App Adds To Cart' },
//       { id: `${type}.app_custom_event.fb_mobile_add_to_wishlist`, name: 'Mobile App Adds to Wishlist' },
//       { id: `${type}.app_custom_event.fb_mobile_complete_registration`, name: 'Mobile App Registrations' },
//       { id: `${type}.app_custom_event.fb_mobile_content_view`, name: 'Mobile App Content Views' },
//       { id: `${type}.app_custom_event.fb_mobile_initiated_checkout`, name: 'Mobile App Checkouts' },
//       { id: `${type}.app_custom_event.fb_mobile_level_achieved`, name: 'Mobile App Achievements' },
//       { id: `${type}.app_custom_event.fb_mobile_purchase`, name: 'Mobile App Purchases' },
//       { id: `${type}.app_custom_event.fb_mobile_rate`, name: 'Mobile App Ratings' },
//       { id: `${type}.app_custom_event.fb_mobile_search`, name: 'Mobile App Search' },
//       { id: `${type}.app_custom_event.fb_mobile_spent_credits`, name: 'Mobile App Credit Spends' },
//       { id: `${type}.app_custom_event.fb_mobile_tutorial_completion`, name: 'Mobile App Tutorial Completions' },
//       { id: `${type}.mobile_app_install`, name: 'Mobile App Installs' },
//       // { id: `${type}.app_installs`, name: 'App Installs' },
//       // { id: `${type}.app_uses`, name: 'App Uses' }, // TODO: not sure about this one. app_custom_event?
//       // { id: `${type}.check_ins`, name: 'Check_ins' },
//       // { id: `${type}.credit_spends`, name: 'Credit Spends' },
//       // { id: `${type}.game_plays`, name: 'Game Plays' },
//       // { id: `${type}.page_likes`, name: 'Page Likes' },
//       // { id: `${type}.blocked_messaging_conversations`, name: 'Blocked Messaging Conversations' },
//       // { id: `${type}.messaging_conversations_started`, name: 'Messaging Conversations Started' },
//       // { id: `${type}.new_messaging_conversations`, name: 'New Messaging Conversations' },
//       // { id: `${type}.outbound_clicks`, name: 'Outbound Clicks' },
//       // { id: `${type}.page_photo_views`, name: 'Page Photo Views' },
//       // { id: `${type}.event_responses`, name: 'Event Responses' },
//       // { id: `${type}.schedules`, name: 'Schedules' },
//       // { id: `${type}.applications_submitted`, name: 'Applications Submitted' },
//       // { id: `${type}.recurring_subscription_payments`, name: 'Recurring Subscription Payments' },
//       // { id: `${type}.canceled_subscriptions`, name: 'Canceled Subscriptions' },
//       // { id: `${type}.call_confirmation_clicks`, name: 'Call Confirmation Clicks' },
//       { id: `${type}.offsite_engagement`, name: 'Page Engagement' } // TODO: https://i.kym-cdn.com/photos/images/original/001/365/753/94c.jpg
//       // { id: `${type}.app_activations`, name: 'App Activations' },
//       // { id: `${type}.levels_achieved`, name: 'Levels Achieved' },
//       // { id: `${type}.submitted`, name: 'Submitted' },
//       // { id: `${type}.app_custom_event.fb_mobile_tutorial_completion`, name: 'Tutorials Completed' }
//     ]
//   },
//   {
//     id: 'ad_engagement',
//     name: 'Ad Engagement',
//     items: [
//       { id: `${type}.post_reaction`, name: 'Post Reactions' },
//       { id: `${type}.post_comment`, name: 'Post Comments' },
//       // { id: `${type}.post_save`, name: 'Post Saves' },  // TODO: where are these?
//       // { id: `${type}.post_share`, name: 'Post Shares' },
//       { id: `${type}.post_engagement`, name: 'Engagements' }
//       // { id: `${type}.cost_per_engagement`, name: 'Cost per Engagement' }
//     ]
//   }
//   // TODO: where are these?
//   // {
//   //   id: 'video_ad_engagement',
//   //   name: 'Video Ad Engagement',
//   //   items: [
//   //     { id: `${type}.thruplay`, name: 'Thruplay' },
//   //     { id: `${type}.cost_per_thruplay`, name: 'Cost per Thruplay' },
//   //     { id: `${type}.2_second_plays`, name: '2 Second Plays' },
//   //     { id: `${type}.cost_per_2_second_plays`, name: 'Cost per 2 Second Plays' },
//   //     { id: `${type}.3_second_plays`, name: '3 Second Plays' },
//   //     { id: `${type}.cost_per_3_second_plays`, name: 'Cost per 3 Second Plays' },
//   //     { id: `${type}.10_second_plays`, name: '10 Second Plays' },
//   //     { id: `${type}.cost_per_10_second_plays`, name: 'Cost per 10 Second Plays' },
//   //     { id: `${type}.video_plays_at_25`, name: 'Video plays at 25%' },
//   //     { id: `${type}.video_plays_at_50`, name: 'Video plays at 50%' },
//   //     { id: `${type}.video_plays_at_75`, name: 'Video plays at 75%' },
//   //     { id: `${type}.video_plays_at_95`, name: 'Video plays at 95%' },
//   //     { id: `${type}.video_plays_at_100`, name: 'Video plays at 100%' }
//   //   ]
//   // }
// ]

export default metrics
